<template>
    <div>
        <div class="form-row">
            <div class="form-col">
                <p>add html for customized site content.</p>
                <textarea v-model="content" class="border w-full  h-full mt-8 p-4 focus:outline-none"></textarea>
                <span v-if="hasError('content')" class="form-error">{{ showError('content') }}</span>
            </div>
            <div class="form-col">
                <p class="font-bold">note: adding css styles may affect the rest of elements in the page. </p>
                <p >please try to use css classes instead, use the example as a reference:</p>
                <pre class="bg-gray-100 mt-4">
                  <code>
                    &lt;style&gt;
                      .test-class p {
                        font-size: 20px;
                      }
                    &lt;/style&gt;
                  
                    &lt;div class="test-class"&gt;
                       &lt;p&gt; hello world &lt;/p&gt;
                    &lt;/div&gt;
                  </code>
                </pre>
            </div>
        </div>

        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
import Widget from "@/mixins/Widget";
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooter from "@/components/ui/modals/ModalFooter";

export default {
    mixins: [ ModalNavigation, Widget ],
    components: { ModalFooter },
    emits: ['save-page-widget'],
    data: () => {
        return {
            content: null,
        }
    },
    methods: {
        save() {
            if(this.validator.passes()) {
                this.$emit('save-page-widget', { content: this.content });
            }
        },
    },
    validations: {
        'content' : 'required',
    },
    created() {
        this.initValidator();
        if(this.values.length)
            this.content = this.getValue('content');
    }
}
</script>
